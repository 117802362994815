import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
import VehicleCard from 'src/components/Card/VehicleCard.vue'
export default {
  name: 'InicioAdmin',
  components: { VehicleCard },
  meta () {
    return {
      title: 'Admin Netcarros',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  mounted () {
    this.getFeatured()
  },
  computed: {
    featuredList () {
      return this.marcas
    }
  },
  data () {
    return {
      marcas: [
        { id: 36, name: 'volkswagen', imagem: 'fotos-marcas/marcas/volkswagen.webp', isActive: false, featured: [] },
        { id: 14, name: 'fiat', imagem: 'fotos-marcas/marcas/fiat.webp', isActive: false, featured: [] },
        { id: 5, name: 'chevrolet', imagem: 'fotos-marcas/marcas/chevrolet.webp', isActive: false, featured: [] },
        { id: 35, name: 'toyota', imagem: 'fotos-marcas/marcas/toyota.png', isActive: false, featured: [] }
      ]
    }
  },
  methods: {
    getFeatured () {
      let modelElastic = new ElasticSearchModel()
      this.marcas.forEach((marca) => {
        let filterOptions = [
          { tipo: 'multiplo', termo: 'destaque', label: 'Destaque', valor: true },
          { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 1 },
          { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 6 },
          { tipo: 'multiplo', termo: 'marca_objeto.marca_id', label: 'Marca', valor: marca.id }
        ]
        modelElastic.buscar(filterOptions, null, 1, 8).then((retorno) => {
          marca.featured = retorno.hits.hits.map((item) => item._source)
        }).catch((e) => console.error('e', e))
      })
      // let filterOptions = [
      //   { tipo: 'multiplo', termo: 'destaque', label: 'Destaque', valor: true },
      //   { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 1 },
      //   { tipo: 'multiplo', termo: 'situacao_id', label: 'Situação', valor: 6 }
      // ]
      // if (this.marcaId) {
      //   filterOptions.push({ tipo: 'multiplo', termo: 'marca_objeto.marca_id', label: 'Marca', valor: this.marcaId })
      // }
      // modelElastic.buscar(filterOptions, null, 1, 8).then((retorno) => {
      //   this.featured = retorno.hits.hits.map((item) => item._source)
      // }).catch((e) => console.error('e', e))
    }
  }

}
